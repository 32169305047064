import Vue, { PropType } from "vue";
import { VNode } from "vue/types/umd";

type Nullable<T> = null | T;

type RouteFactory = (
  item: any
) => { url: Nullable<string> | undefined; title: Nullable<string> | undefined };

export default (factory: RouteFactory) =>
  Vue.extend({
    props: {
      item: Object as PropType<any>
    },

    computed: {
      url(): Nullable<string> | undefined {
        const url = factory(this.item)?.url;
        if (!url) return undefined;

        if (/^https?:\/\//i.test(url)) {
          return url;
        }

        if (/^www\./i.test(url)) {
          return `https://${url}`;
        }

        return url;
      },
      title(): Nullable<string> | undefined {
        return factory(this.item)?.title;
      }
    },

    render(h): VNode {
      const link = h(
        "a",
        {
          attrs: { href: this.url, target: "_blank" }
        },
        this.title
      );
      return link;
    }
  });
