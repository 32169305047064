




































































































import Vue, { PropType } from "vue";
import camelcaseKeys from "camelcase-keys";

import {
  Nullable,
  DialogStep,
  FormLabel,
  FormScope,
  ExportBatch
} from "@/types";
import { AxiosError } from "axios";
import CancelBatch from "../CancelBatch.vue";

export default Vue.extend({
  components: {
    CancelBatch
  },
  props: {
    value: Boolean,

    title: [Function, String] as PropType<FormLabel>,

    onClose: Function as PropType<(this: FormScope) => void>,

    width: {
      type: [String, Number],
      default: "80vw"
    },

    maxWidth: {
      type: [String, Number],
      default: "1200"
    },

    formDataFactory: {
      type: Function as PropType<(this: FormScope) => any>,
      default: () => () => ({})
    },

    steps: {
      type: Array as PropType<DialogStep[]>,
      default: () => []
    }
  },

  computed: {
    formScope(): FormScope {
      return {
        $data: this.formData ?? {},
        $attrs: camelcaseKeys(this.$attrs),
        $i18n: this.$i18n,
        $store: this.$store,
        $t: this.$t,
        $emit: this.$emit.bind(this)
      };
    },

    hasProgressBar(): boolean {
      return !!this.formScope.$data?.batch;
    },

    errorMessage(): string {
      return this.error?.response?.data?.message || this.error?.message;
    },

    errorValidation(): Record<string, string> {
      return this.error?.response?.data.data;
    },

    labelTitle(): string {
      const title = this.step?.labels?.title ?? this.title;

      if (typeof title === "string") return this.$t(title).toString();

      return title.apply(this.formScope);
    },

    labelSubtitle(): string | undefined {
      const title = this.step?.labels?.subtitle;

      if (typeof title === "string") return this.$t(title).toString();

      return title?.apply(this.formScope);
    },

    labelAction(): string {
      const title = this.step?.labels?.action;

      if (!title) return this.$t("dialogs.next").toString();

      if (typeof title === "string") return this.$t(title).toString();

      return title.apply(this.formScope);
    },

    step(): DialogStep {
      return this.steps[this.currentStep];
    },

    stepValid() {
      return (i: number) => {
        if (i < 0) return true;

        if (!this.stepValid(i - 1)) return false;

        const step = this.steps[i];

        if (step?.isValid) {
          return step.isValid.apply(this.formScope);
        }

        return true;
      };
    },

    isValid(): boolean {
      return !!this.formData && this.stepValid(this.currentStep);
    },

    stepValue: {
      get(): any {
        return this.step.value?.get?.apply(this.formScope);
      },

      set(val: any) {
        this.step?.value?.set?.apply(this.formScope, [val]);
        this.formData = { ...this.formData };
      }
    },

    stepSyncProps(): Record<string, any> {
      if (!this.step.sync) return {};

      return Object.keys(this.step.sync).reduce((props, key) => {
        let sync = this.step.sync?.[key];
        let value: any;

        if (typeof sync === "string") {
          value = this.formData?.[sync];
        } else if (sync !== undefined) {
          value = sync.get?.apply(this.formScope);
        }

        return { ...props, [key]: value };
      }, {});
    },

    stepListeners(): Record<string, (val: any) => void> {
      if (!this.step.sync) return {};

      return Object.keys(this.step.sync).reduce(
        (props, key) => ({
          ...props,
          [`update:${key}`]: (val: any) => {
            let sync = this.step.sync?.[key];

            if (typeof sync === "string") {
              this.$set(this.formData!, sync, val);
            } else if (sync !== undefined) {
              sync?.set?.apply(this.formScope, [val]);
            }
          }
        }),
        {}
      );
    },

    stepProps(): Record<string, any> {
      if (!this.step.props) return this.stepSyncProps;

      if (typeof this.step.props === "object") {
        return { ...this.step.props, ...this.stepSyncProps };
      }

      return {
        ...this.step.props.apply(this.formScope),
        ...this.stepSyncProps
      };
    },
    batch(): ExportBatch | null {
      return this.formScope?.$data?.batch ?? {};
    }
  },

  data() {
    return {
      batchDialog: false,
      error: null as Nullable<AxiosError>,
      currentStep: 0,
      formData: {} as Nullable<Record<string, any>>,
      isPending: false
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(val: boolean) {
        if (val) {
          this.formData = this.formDataFactory?.apply(this.formScope);
          this.step?.beforeStep?.apply(this.formScope);
        }
      }
    }
  },

  methods: {
    close() {
      this.batchDialog = false;
    },
    cancel() {
      if (
        this.formScope.$data.batch &&
        !this.formScope.$data.batch.finishedAt &&
        !this.formScope.$data.batch.cancelledAt
      ) {
        this.batchDialog = !this.batchDialog;
        return;
      }
      this.$emit("input", false);
      this.onClose?.apply(this.formScope);
      this.step?.onClose?.apply(this.formScope);

      this.$store.dispatch(`projects/regularHouses/settings/search`);
    },

    cancelBatch() {
      this.$emit("input", false);
      this.onClose?.apply(this.formScope);
      this.step?.onClose?.apply(this.formScope);

      this.$store.dispatch(`projects/regularHouses/settings/search`);
    },

    async nextStep() {
      this.isPending = true;

      try {
        await this.step?.afterStep?.apply(this.formScope);
      } catch (e) {
        this.error = e;
        this.isPending = false;
        return;
      }

      if (this.currentStep + 1 < this.steps.length) {
        this.currentStep++;

        try {
          await this.step?.beforeStep?.apply(this.formScope);
        } catch (e) {
          this.error = e;
        }
      } else {
        this.cancel();
      }

      this.isPending = false;
    }
  }
});
